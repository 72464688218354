/* eslint-disable react/no-unescaped-entities */
import { PageContainer, TitleElement } from "common/components/StyledElements";
import { useEffect, useRef } from "react";
import styled from "styled-components";

const Container = styled(PageContainer)`
  padding-top: 50px;
  text-align: justify;
`;
const PageTitle = styled(TitleElement)`
  font-size: 24px;
  text-align: center;
`;
const ParagraphContainer = styled.p`
  font-size: 18px;
`;
const Separator = styled.div`
  height: 15px;
`;

const TermsPage = () => {
  const careerRef = useRef(null);

  useEffect(() => {
    careerRef?.current?.scrollIntoView({ behavior: "smooth" });
  }, [careerRef]);

  return (
    <Container>
      <PageTitle>Terms & Conditions</PageTitle>
      <ParagraphContainer>
        <Separator />
        1. General In terms of Information Technology Act, 2000, this document
        is an electronic record. Being generated by a computer system it does
        not require any physical or digital signatures.
        <Separator />
        2. This document is published in accordance with the provisions of Rule
        3 (1) of the Information Technology (Intermediaries guidelines) Rules,
        2011 that require publishing the rules and regulations, privacy policy
        and Terms of Use for access or usage of ValueMedi Mobile
        Application/Valuemedi Web Application.
        <Separator />
        The name ValueMedi (hereinafter referred to as "ValueMedi Mobile
        Application"/ “ Valuemedi Web Application”) is owned by Ennea Solutions
        Pvt. Ltd, a Company incorporated under the Companies Act, 2013
        (Hereinafter referred to as the (“Company”) having its Registered office
        at H no: 8-2-293/82/1/1293/A, 1st Floor, Raod no 63, Jubilee Hills,
        Hyderabad- 500034, Telangana, India (Hereinafter referred to as
        “ValueMedi”).
        <Separator />
        3. The usage of Website/ Valuemedi Web Application/ ValueMedi Mobile
        Application shall hereinafter be referred to as “Interface”.
        <Separator />
        4. ValueMedi is a mobile Application/web Application, a dynamic order
        management of transactions between B2B clients or retailers ordering
        goods from Distributors/Stockists/Wholesalers. It is a unique tool in
        the hands of the retailers for ordering goods, increasing the efficiency
        of the Distributors/Stockists/Wholesalers in execution of the orders,
        and supporting the retailers to meet the day to day needs of the end
        customers.
        <Separator />
        5. Your use of our ValueMedi Mobile/Web Application provides evidence
        that you have read and agreed to these Terms & Conditions and our
        PRIVACY POLICY. Please read both carefully. The use of this ValueMedi
        Mobile/Web Application by You is governed by this policy and any policy
        so mentioned by terms of reference.
        <Separator />
        6. Moving past the home page, or using any of the services shall be
        taken to mean that You have read and agreed to all the policies so
        binding on You and that You are contracting with the Company and have
        undertaken binding obligations with the company. If you do not agree
        with any of these terms, please exit ValueMedi. Any user of this
        ValueMedi Mobile/Web Application hereby accepts that any usage of the
        ValueMedi Mobile/Web Application by him or her shall be construed that
        such user has read, understood, and accepted the Terms & Conditions and
        Privacy Policy herein contained.
        <Separator />
        7. For the purpose of these Terms & Conditions, wherever the context so
        requires "You" or "User" shall mean any natural or legal person who has
        agreed to use the ValueMedi Mobile/Web Application to browse and fetch
        Data services. The ValueMedi Mobile Application providing its services
        without registration does not absolve You of this contractual
        relationship. The term "We", "Us", "Our" shall mean ValueMedi or
        Company. You will be subject to the rules, guidelines, policies, terms,
        and conditions applicable to any service that is provided by this
        ValueMedi Mobile/Web Application, and they shall be deemed to be
        incorporated into these Terms & Conditions and shall be considered as
        part and parcel of these Terms & Conditions. We hold the sole right to
        modify the Terms & Conditions without prior permission from You or
        informing You. The relationship creates on You a duty to periodically
        check the terms and stay updated on its requirements. If You continue to
        use the ValueMedi Mobile/Web Application following such a change, this
        shall be deemed that you have consented to the amended policies. As long
        as You comply with these Terms & Conditions, we grant You a personal,
        non-exclusive, non-transferable, limited privilege to enter and use the
        ValueMedi Mobile/Web Application. By impliedly or expressly accepting
        these Terms & Conditions, you also accept and agree to be bound by other
        ValueMedi Policies, inter alia Privacy Policy, which may be amended from
        time to time.
        <Separator />
        8. Usage: The use of this ValueMedi Mobile/Web Application is available
        only to those above the age of 18 barring those ‘Incompetent to
        Contract’ which inter alia include insolvents and the same is not
        allowed to minors as described by the Indian Contract Act, 1872. We
        reserve the right to restrict access to part/ whole of the ValueMedi
        Mobile/Web Application.
        <Separator />
        9. Communication: Upon becoming a registered user you may receive calls
        for explaining the features of this ValueMedi Mobile/Web Application and
        for the purpose of clarifying the queries if any raised by you. The
        sharing of the information provided by you will be governed by the
        Privacy Policy and We will not give out such contact information of
        yours to third parties not connected with the services being offered.
        <Separator />
        10. Charges: Ennea is committed to ensuring that the application is as
        useful and efficient as possible. For that reason, we reserve the right
        to make changes to the ValueMedi Mobile/Web Application or to charge for
        its services, at any time and for any reason. We will never charge you
        for the ValueMedi Mobile/Web Application or its services without making
        it very clear to you exactly what you’re paying for. The registration on
        this ValueMedi Mobile/Web Application as a retailer/ Distributor or such
        other nomenclature used by the service seeker on this ValueMedi
        Mobile/Web Application for ordering of the goods or such other services
        may entail payment of fees/commission/ subscription fees at regular
        intervals. The Users will be intimated of the payments to be made and it
        will be up to you to decide whether you will continue with services
        offered by us. Such charges are effective as soon as they are posted on
        the ValueMedi Mobile/Web Application. If you are using the ValueMedi
        Mobile/Web Application, you should remember that your terms of agreement
        with your mobile network provider will still apply. As a result, you may
        be charged by your mobile provider for the cost of data for the duration
        of the connection while accessing ValueMedi Mobile/Web Application, or
        other third-party charges. By using ValueMedi Mobile/web Application,
        you are accepting responsibility for any such charges, including roaming
        data charges if you use ValueMedi Mobile/Web Application outside your
        home territory (i.e. region or country) without turning off data
        roaming.
        <Separator />
        11.Third Party Information: All text, graphics, user interfaces, visual
        interfaces, photographs, trademarks, logos, sounds, music and artwork
        (collectively, "Content"), includes our content and third-party user
        generated content and we assume no responsibility for the third party
        generated content. Other than when provided for, the use of such content
        and it being reproduced, republished, uploaded, posted, publicly
        displayed, encoded, translated, transmitted, or distributed in any way
        (including "mirroring") to any other computer, server, Website or other
        medium for publication or distribution or for any commercial enterprise,
        is prohibited. The content that you post in the form of reviews,
        suggestions and feedback will become our property and You grant Us the
        worldwide, perpetual, and transferable rights in such Content. We shall
        be entitled to, consistent with Our Privacy Policy as adopted in
        accordance with applicable law, use the Content or any of its elements
        for any type of use forever, including but not limited to promotional
        and advertising purposes and in any medium whether now known or
        hereafter devised, including the creation of derivative works that may
        include the Content You provide and are not entitled to any payment or
        other compensation for such use.
        <Separator />
        12. User Obligation: You are a restricted user of this ValueMedi
        Mobile/Web Application. You are bound not to Cut, copy, distribute,
        modify, recreate, reverse engineer, distribute, disseminate, post,
        publish or create derivative works from, transfer, or sell any
        information or software obtained from the Web site/mobile ValueMedi
        Mobile/Web Application. For the removal of doubts, it is clarified that
        unlimited or wholesale reproduction, copying of the content for
        commercial or non-commercial purposes and unwarranted modification of
        data and information within the content of the Web site/mobile ValueMedi
        Mobile/Web Application is prohibited. You agree not to access (or
        attempt to access) the Web site/ ValueMedi Mobile/Web Application and/or
        the materials or Services by any means other than through the interface
        that is provided by the mobile /Web site as a case may be. The use of
        deep-link, robot, spider or other automatic device, program, algorithm,
        or methodology, or any similar or equivalent manual process, to access,
        acquire, copy, or monitor any portion of the Web site or Content, or in
        any way reproduce or circumvent the navigational structure or
        presentation of the Web site, materials or any Content, to obtain or
        attempt to obtain any materials, documents or information through any
        means not specifically made available through the Web site. You
        acknowledge and agree that by accessing or using the Web site or
        Services, you may be exposed to content from other users that You may
        consider offensive, indecent or otherwise objectionable. We disclaim all
        liabilities arising in relation to such offensive content on the Web
        site. Further, you may report such offensive content. In places where
        this Web site/ValueMedi Mobile/Web Application allows you to post or
        upload data/information, you undertake to ensure that such material is
        not offensive, derogatory, indecent or scandalous and in accordance with
        applicable laws.
        <Separator />
        13. Disclaimer of warranties and liabilities: you expressly understand
        and agree that, to the maximum extent permitted by applicable law: THE
        ValueMedi Mobile/web application, services and other materials are
        provided by this Valuemedi Mobile/Web application is on an "as is" basis
        without warranty of any kind, express, implied, statutory or otherwise,
        including the implied warranties of title, non-infringement,
        merchantability or fitness for a particular purpose. without limiting
        the foregoing, Ennea and Valuemedi makes no warranty that (i) your
        requirements will be met or that services provided will be
        uninterrupted, timely, secure, or error-free; (ii) materials,
        information obtained and results will be effective, accurate, or
        reliable; (iii) any errors or defects in the web site, services or other
        materials will be corrected. to the maximum extent permitted by
        applicable law, we will have no liability related to user content
        arising under intellectual property rights, libel, privacy, publicity,
        obscenity, or other laws. We also disclaim all liability with respect to
        the misuse, loss, modification, or unavailability of any user content.
        We and our affiliates are not responsible or liable for content posted
        by third parties, actions of any third party, or for any damage to, or
        virus that may infect, your computer equipment or other property. Ennea
        and Valuemedi Mobile/Web application contains facts, views, opinions,
        statements and recommendations of third-party individuals and
        organizations. We do not represent or endorse the accuracy, correctness
        or reliability of any advice, opinion, statement, or other information
        displayed, uploaded or distributed in any manner. any reliance upon any
        such opinion, advice, statement, or information is at your risk and we
        shall not be held liable in any way. in no event shall we or our
        affiliates, employees, agents, content providers or licensors be liable
        for any indirect, consequential, special, incidental, or punitive
        damages including, without limitation, damages related to unauthorized
        access to or alteration of your transmissions or data, the content or
        any errors or omissions in the content, even if advised of the
        possibility of such damages. In no event shall we or our affiliates,
        employees, agents, content providers or licensors be liable for any
        amount for direct or indirect damages. the user understands and agrees
        that any material or data downloaded or otherwise obtained through the
        web site or Valuemedi Mobile/Web application is done entirely at his /
        her own discretion and risk and such user will be solely responsible for
        any damage to his/ her computer systems or loss of data that results
        from the download of such material or data. we shall not be liable for
        any third-party product or services. the advertisement available on
        e-mail or web site with respect to the third-party web site or the
        products and services are for information purpose only.
        <Separator />
        14. Indemnification and Limitation of Liability: You agree to indemnify,
        defend and hold harmless this Valuemedi Mobile/Web application including
        but not limited to its affiliate vendors, agents and employees from and
        against any and all losses, liabilities, claims, damages, demands, costs
        and expenses (including legal fees and disbursements in connection
        therewith and interest chargeable thereon) asserted against or incurred
        by us that arise out of, including, but not limited to, claims for
        defamation, trade disparagement, privacy and intellectual property
        infringement, any breach or non-performance of any representation,
        warranty, covenant or agreement made or obligation to be performed by
        you pursuant to these terms of use) and damages (including attorneys'
        fees and court costs) arising from or relating to any allegation
        regarding: (1) your use of the Valuemedi Mobile/Web Application (2) our
        use of any content or information you provide, as long as such use is
        not inconsistent with this agreement; (3) information or material posted
        or transmitted through your membership account, even if not posted by
        you; and, (4) any violation of this agreement by you. further, you agree
        to hold us harmless against any claims made by any third party due to,
        or arising out of, or in connection with, your use of the web site, any
        claim that your material caused damage to a third party, your violation
        of the terms of use, or your violation of any rights of another,
        including any intellectual property rights. in no event shall we,
        officers, directors, employees, partners or suppliers be liable to you,
        the vendor or any third party for any special, incidental, indirect,
        consequential or punitive damages whatsoever, including those resulting
        from loss of use, data or profits, whether or not foreseeable or whether
        or not we have been advised of the possibility of such damages, or based
        on any theory of liability, including breach of contract or warranty,
        negligence or other tortious action, or any other claim arising out of
        or in connection with your use of or access to the Valuemedi, services
        or materials. The limitations and exclusions in this section apply to
        the maximum extent permitted by applicable laws.
        <Separator />
        15. Third party information ValueMedi Mobile/Web Application acts as an
        intermediary to order the goods by a retailer. You agree to not hold us
        liable for the falsification of any such provided information. Your
        dealings or communications through the ValueMedi Mobile/Web Application
        with any party are solely between you and that third party (Retailer/
        Distributor/ such other nomenclatures). Under no circumstances will we
        be liable for any goods, services, resources or content available
        through such third-party dealings or communications, or for any harm
        related thereto. Please review carefully that third party's policies and
        practices and make sure you are comfortable with them before you engage
        in any transaction. Complaints, concerns, or questions relating to
        materials provided by third parties should be forwarded directly to the
        third party. During your visit to the ValueMedi Mobile/Web Application
        you may link to, or view as part of a frame, certain content that is
        created or hosted by a third party. You may be introduced to, or be able
        to access, information, Web sites, advertisements, features, contests,
        or sweepstakes offered by other parties. We are not responsible for the
        actions or policies of such third parties. You should check the
        applicable Terms & Conditions and privacy policies of those third
        parties when providing information on such a feature or page.
        <Separator />
        16. COPYRIGHT: All information, content, services and software displayed
        on, transmitted through, or used in connection with the ValueMedi
        Mobile/Web Application, including for example advertisements, offers,
        product information, news articles, reviews, directories, guides, text,
        photographs, images, illustrations, audio clips, video, html, source and
        object code, trademarks, logos, and the like (collectively, the
        "Content"), as well as its selection and arrangement, is owned by us,
        and its affiliated companies, licensors and suppliers. You may use the
        Content online only. You shall not republish any portion of the Content
        on any Internet, Intranet or extranet site or incorporate the Content in
        any database, compilation, archive or cache. You shall not distribute
        any Content to others, whether for payment or other consideration, and
        you shall not modify, copy, frame, cache, reproduce, sell, publish,
        transmit, display or otherwise use any portion of the Content. You shall
        not scrape or otherwise copy our Content. You agree not to decompile,
        reverse engineer or disassemble any software or other products or
        processes accessible through the ValueMedi Mobile/Web Application, not
        to insert any code or product or manipulate the content of ValueMedi in
        any way that affects the user's experience, and not to use any data
        mining, data gathering or extraction method.
        <Separator />
        17. DISCLAIMER: The content on the ValueMedi Mobile/Web Application is
        summarized, and is provided for placing the orders by the retailer to
        the distributor and the reports generated based on the interse
        transactions between the retailers and distributors. The content of the
        ValueMedi Mobile/Web Application, including without limitation, text,
        copy, audio, video, photographs, illustrations, graphics and other
        visuals, is for informational purposes only and does not constitute
        professional advice, or recommendations of any kind. You do hereby grant
        us a non-exclusive, worldwide, perpetual, irrevocable, royalty-free,
        sub-licensable right to exercise the copyright, publicity, and database
        rights you have in Your information, in any media now known or not
        currently known, with respect to Your information to enable Us to use
        the information for the purpose of providing the Services.
        <Separator />
        18. Web site/ Mobile phone is an Intermediary Platform. The ValueMedi
        Mobile/Web Application is a platform that Users namely Retailers and
        Distributors utilize to meet and interact with each other for their
        transactions. We are not a party to such interaction and take no
        liability that arises from any such communication. All communication
        which inter alia includes the contract, its terms, mutual obligations,
        prices, etc are outcomes of the communication between the You. This
        includes, without any limitation, the prices, shipping costs, payment
        details, date, period and mode of delivery, warranties related to
        products and services and after sales services related to products and
        services. We do not have any control over such information and play no
        determinative role in the finalization of the same and hence do not
        stand liable for the outcomes of such communication. We do not endorse
        any of the products/services offered for sale on the ValueMedi
        Mobile/Web Application nor place any guarantee as to its nature, price,
        quality, etc. Any breach of contract or terms and conditions agreed
        between the users of ValueMedi Mobile/Web Application shall be contested
        or settled between by such users and we shall not in any manner
        whatsoever be liable or responsible for the disputes between the users
        of ValueMedi Mobile/Web application. The users are expected to check the
        creditworthiness of the other party and the genuineness of the products
        or services offered. We are not liable for the same. As the contract is
        limited to the retailer and distributor and not Us, we are in no way
        liable for defects in goods, and/or deficiency in service. As we hold no
        possession, nor title of the products at any time, or enter/determine
        the communication between the buyer and the seller or determine its
        outcome, the contract is purely a bipartite contract between the buyer
        and the seller and We are not responsible for claims arising from such a
        contract. You release and indemnify Us and/or any of its officers and
        representatives from any cost, damage, liability, or other consequence
        of any of the actions of the Users of the ValueMedi Mobile/Web
        Application and specifically waive any claims that you may have in this
        behalf under any applicable law. Please note that there could be risks
        in dealing with underage persons or people acting under false pretence.
        <Separator />
        19. MISCELLANEOUS: We reserve the right to change these Terms &
        Conditions at any time in its discretion and to notify users of any such
        changes solely by changing this Terms & Conditions. Your continued use
        of the Web site after the posting of any amended Terms & Conditions
        shall constitute your agreement to be bound by any such changes. Your
        use of this site prior to the time this Terms & Conditions was posted
        will be governed according to the Terms & Conditions that ValueMedi
        Mobile/Web Application lied at the time of your use. We may modify,
        suspend, discontinue, or restrict the use of any portion, including the
        availability of any portion of the Content at any time, without notice
        or liability. We may deny access to any person or user at any time for
        any reason. In addition, we may at any time transfer rights and
        obligations under this Agreement to any affiliate, subsidiary, or
        business unit, or any of their affiliated companies or divisions, or any
        entity that acquires ValueMedi or any of their assets. We hereby reserve
        the right to block usage of the Website and the ValueMedi Mobile/Web
        Application if any breach of the Terms & Conditions is caused by a User.
        This can in no way be construed as a waiver of any legal right under
        Indian law to claim any damages or initiate any legal proceedings.
      </ParagraphContainer>
    </Container>
  );
};

export default TermsPage;
