const urls = {
  PROD: "https://api.valuesale.in/",
  LOCAL: "http://localhost:8081/"
};

export const GTMTags = {
  BETA: "G-XS3DN1T9HS",
  PROD: "G-LV2YFJKW5F"
};

const baseURL = urls[process.env.REACT_APP_URL_ENV] ?? urls.PROD;

const defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json"
};

const logoutURL = `${baseURL}api/auth/logout`;
const loginURL = `${baseURL}api/auth/login`;
const loginWithTokenURL = `${baseURL}api/auth/tokenLogin`;
const phoneNumberLogin = `${baseURL}api/auth/login/number`;
const otpGenerate = `${baseURL}api/v1/otp/generate`;
const otpValidate = `${baseURL}api/v1/otp/validate`;
const resetPassword = `${baseURL}api/v1/utils/reset-password`;
const refreshToken = `${baseURL}api/auth/refresh`;
const cartDetailsURL = `${baseURL}api/v1/cart-new/get-cart-details`;
const allAssociatedUsers = `${baseURL}api/v1/customers/all`;
const allAssociatedUsersList = `${baseURL}api/v1/customers/all/list`;
const newUsersURL = `${baseURL}api/v1/user/new`;
const searchUsersURL = `${baseURL}api/v1/user/search`;
const suggestedCustomers = `${baseURL}api/v1/user/suggested-customers`;
const searchCustomers = `${baseURL}api/v1/user/search-customers`;
const searchSupplierProductDetails = `${baseURL}api/v1/inventory/customer/search`;
const searchSupplierProducts = `${baseURL}api/v1/inventory/supplier/search`;
const getOrderDetails = `${baseURL}api/v1/order`;
const reorder = `${baseURL}api/v1/order/reorder`;
const findOrders = `${baseURL}api/v1/order/find-orders`;
const orderTypes = `${baseURL}api/v1/order/allTypes`;
const orderStatuses = `${baseURL}api/v1/order/allStatuses`;
const addToCart = `${baseURL}api/v1/cart-new/create`;
const updateCart = `${baseURL}api/v1/cart-new/update`;
const updateCartHold = `${baseURL}api/v1/cart-new/hold`;
const clearCart = `${baseURL}api/v1/cart-new/clear`;
const analytics = `${baseURL}api/v1/analytics/all`;

const dashboardUrl = `${baseURL}api/v1/analytics/dashboard-url`;
const divisionsURL = `${baseURL}api/v1/inventory/divisions/search`;
const paymentCollectionStatuses = `${baseURL}api/v1/payment/all-collection-statuses`;
const paymentRequests = `${baseURL}api/v1/payment/requests`;
const raisePaymentRequest = `${baseURL}api/v1/payment/raise-request`;
const payPayment = `${baseURL}api/v1/payment/pay`;
const userProfile = `${baseURL}api/v1/user/profile`;
const updateUserProfile = `${baseURL}api/v1/user/update-profile`;
const userNumber = `${baseURL}api/v1/user-number`;
const removeUserNumber = `${baseURL}api/v1/user-number/session`;
const removeAllUserNumbers = `${baseURL}api/v1/user-number/all-sessions`;
const invoiceDetails = `${baseURL}api/v1/invoice`;
const getAllInvoices = `${baseURL}api/v1/invoice/find-all`;
const inventoryDetails = `${baseURL}api/v1/inventory/supplier/all`;
const updateInventoryStatus = `${baseURL}api/v1/inventory/update-visibility`;
const updateProductDetails = `${baseURL}api/v1/inventory/`;
const supportCreateRequest = `${baseURL}api/v1/support/createRequest`;
const createReturn = `${baseURL}api/v1/order/return`;
const representatives = `${baseURL}api/v1/representative/all`;
const representativesList = `${baseURL}api/v1/representative/all-list`;
const updateRepresentative = `${baseURL}api/v1/representative/update`;
const updateDivisionSupervisor = `${baseURL}api/v1/representative/update/division-supervisor`;
const allStatuses = `${baseURL}api/v1/user/all-statuses`;
const userStatuses = `${baseURL}api/v1/user/all-user-statuses`;
const requestStatuses = `${baseURL}api/v1/user/all-request-statuses`;
const supplierLedger = `${baseURL}api/v1/ledger/all`;
const supplierRepresentativeList = `${baseURL}api/v1/representative/all-list/0`;
const ledgersList = `${baseURL}api/v1/ledger/representative`;
const placeLedgerOrder = `${baseURL}api/v1/order/create-by-ledger`;
const customersUpdate = `${baseURL}api/v1/customers/update`;
const customersBlock = `${baseURL}api/v1/customers/status`;
const singleSupplierProductsList = `${baseURL}api/v1/inventory/customer/list`;
const updateOrder = `${baseURL}api/v1/order/update-order`;
const updateApprovalAction = `${baseURL}api/v1/order/update-approval-status`;
const cartPlaceOrder = `${baseURL}api/v1/order/create`;
const supplierSettings = `${baseURL}api/v1/settings/fetch`;
const updateSupplierSettings = `${baseURL}api/v1/settings/update`;
const receivedOrdersCustomers = `${baseURL}api/v1/order/pending/customers`;
const approverAnalytics = `${baseURL}api/v1/representative/analytics`;
const updateCustomerOrdersToInvoicing = `${baseURL}api/v1/order/manual-invoicing`;
const updateOrderByAction = `${baseURL}api/v1/order/action`;
const uploadPurchaseOrder = `${baseURL}api/v1/purchaseOrder/parseSheet`;
const createInventoryMapping = `${baseURL}api/v1/inventory-mapping`;
const updatePurchaseOrderStatus = `${baseURL}api/v1/purchaseOrder/updateUploadStatus`;
const allPurchaseOrders = `${baseURL}api/v1/purchaseOrder/all`;
const allPurchaseOrdersV2 = `${baseURL}api/v2/purchaseOrder/all`;
const newNotifications = `${baseURL}api/v1/notifications/new`;
const allNotifications = `${baseURL}api/v1/notifications/all`;
const updateNotifications = `${baseURL}api/v1/notifications/all`;
const tokenByUserId = `${baseURL}api/v1/user/token`;
const existingDivisionMapping = `${baseURL}api/v1/division-mapping/existing-mapping`;
const deletePurchaseOrder = `${baseURL}api/v1/purchaseOrder/delete`;
const searchUsersViewByRoleName = `${baseURL}api/v1/user/list/view`;
const representativeDivisions = `${baseURL}api/v1/representative/divisions`;
const updateTargets = `${baseURL}api/v1/representative/update-targets`;
const representativeCreate = `${baseURL}api/v1/representative/create`;
const representativeUpdate = `${baseURL}api/v1/representative/update-details`;
const updatePayment = `${baseURL}api/v1/payment/update`;
const paymentTypes = `${baseURL}api/v1/payment/types`;
const paymentsDownload = `${baseURL}api/v1/payment/report`;
const uploadInvoices = `${baseURL}api/v1/invoice/upload`;
const invoiceAction = `${baseURL}api/v1/invoice/action`;
const invoiceDetailsWithUpdateId = `${baseURL}api/v1/invoice/details-for-update`;
const invoiceActionWithUpdateId = `${baseURL}api/v1/invoice/action-with-updateId`;
const uploadInventory = `${baseURL}api/v1/inventory/inventory-ingestion`;
const uploadLedger = `${baseURL}api/v1/ledger/ledger-ingestion`;
const purchaseOrderSupplierMapping = `${baseURL}api/v2/purchaseOrder/supplier-mapping`;
const purchaseOrderInventoryMapping = `${baseURL}api/v2/purchaseOrder/inventory-mapping`;
const uploadPurchaseOrderV2 = `${baseURL}api/v2/purchaseOrder/upload`;
const deletePoDetails = `${baseURL}api/v2/purchaseOrder/details`;
const invoiceStatus = `${baseURL}api/v1/invoice/statuses`;
const outstandingInvoices = `${baseURL}api/v1/invoice/outstanding`;
const allStates = `${baseURL}api/v1/utils/states`;
const allDistricts = `${baseURL}api/v1/utils/districts/`;
const updateThreshold = `${baseURL}api/v1/inventory/update/threshold`;
const userSettings = `${baseURL}api/v1/user/settings/fetch`;
const updateSettings = `${baseURL}api/v1/user/settings/save`;
const saveParserConfig = `${baseURL}api/v1/parser-config/save`;
const fetchParserConfigTemplate = `${baseURL}api/v1/parser-config/fetch-template`;
const fetchFileHeader = `${baseURL}api/v1/parser-config/fetch-header`;
const updateSalesmanCodes = `${baseURL}api/v1/representative/update-salesman-codes/`;
const updateLedger = `${baseURL}api/v1/ledger/update`;
const removeLocation = `${baseURL}api/v1/ledger/remove-location`;
const paymentOutstanding = `${baseURL}api/v1/payment/outstanding`;
const findOrdersByProductSearch = `${baseURL}api/v1/order/find-orders/productSearch`;
const updateLocation = `${baseURL}api/v1/location/update`;
const updatePurchaseOrderDetail = `${baseURL}api/v2/purchaseOrder/update`;
const getRepresentativeAttendance = `${baseURL}api/v1/representative/attendance`;
const getRepresentativeLedgers = `${baseURL}api/v1/ledger/representative`;
const createLedgerOrder = `${baseURL}api/v1/order/create-ledger-order`;
const flipPaymentNotificationSetting = `${baseURL}api/v1/settings/update/paymentNotification`;
const representativeList = `${baseURL}api/v1/representative/list`;
const ledgerDivisions = `${baseURL}api/v1/ledger/approver`;
const depotsUrl = `${baseURL}api/v1/inventory/depot`;
const updateDepots = `${baseURL}api/v1/representative/depot`;
const mapplsToken = `${baseURL}api/v1/mappls/token`;
const mapplsDistance = `${baseURL}api/v1/mappls/distance`;
const getAllExpenses = `${baseURL}api/v1/expense/list`;
const deleteExpense = `${baseURL}api/v1/expense/delete`;

const APIConstants = {
  createLedgerOrder,
  getRepresentativeLedgers,
  getRepresentativeAttendance,
  updateCartHold,
  updatePurchaseOrderDetail,
  defaultHeaders,
  baseURL,
  loginURL,
  phoneNumberLogin,
  otpGenerate,
  otpValidate,
  resetPassword,
  cartDetailsURL,
  allAssociatedUsers,
  allAssociatedUsersList,
  newUsersURL,
  searchUsersURL,
  suggestedCustomers,
  searchCustomers,
  searchSupplierProductDetails,
  getOrderDetails,
  reorder,
  userStatuses,
  requestStatuses,
  orderTypes,
  findOrders,
  orderStatuses,
  addToCart,
  clearCart,
  analytics,
  divisionsURL,
  paymentCollectionStatuses,
  paymentRequests,
  raisePaymentRequest,
  payPayment,
  userProfile,
  userNumber,
  removeUserNumber,
  removeAllUserNumbers,
  updateUserProfile,
  invoiceDetails,
  getAllInvoices,
  inventoryDetails,
  updateInventoryStatus,
  updateProductDetails,
  supportCreateRequest,
  createReturn,
  representatives,
  representativesList,
  updateRepresentative,
  updateDivisionSupervisor,
  allStatuses,
  supplierLedger,
  supplierRepresentativeList,
  ledgersList,
  placeLedgerOrder,
  customersUpdate,
  singleSupplierProductsList,
  updateOrder,
  updateApprovalAction,
  updateCart,
  cartPlaceOrder,
  supplierSettings,
  updateSupplierSettings,
  refreshToken,
  receivedOrdersCustomers,
  updateCustomerOrdersToInvoicing,
  updateOrderByAction,
  uploadPurchaseOrder,
  searchSupplierProducts,
  createInventoryMapping,
  updatePurchaseOrderStatus,
  allPurchaseOrders,
  newNotifications,
  allNotifications,
  updateNotifications,
  logoutURL,
  loginWithTokenURL,
  tokenByUserId,
  existingDivisionMapping,
  deletePurchaseOrder,
  searchUsersViewByRoleName,
  representativeDivisions,
  approverAnalytics,
  updateTargets,
  representativeCreate,
  representativeUpdate,
  updatePayment,
  paymentTypes,
  paymentsDownload,
  uploadInvoices,
  invoiceAction,
  invoiceActionWithUpdateId,
  invoiceDetailsWithUpdateId,
  uploadInventory,
  uploadLedger,
  purchaseOrderSupplierMapping,
  uploadPurchaseOrderV2,
  purchaseOrderInventoryMapping,
  deletePoDetails,
  allPurchaseOrdersV2,
  dashboardUrl,
  customersBlock,
  invoiceStatus,
  allStates,
  allDistricts,
  updateThreshold,
  userSettings,
  updateSettings,
  outstandingInvoices,
  saveParserConfig,
  fetchParserConfigTemplate,
  fetchFileHeader,
  updateSalesmanCodes,
  updateLedger,
  removeLocation,
  paymentOutstanding,
  findOrdersByProductSearch,
  updateLocation,
  flipPaymentNotificationSetting,
  representativeList,
  ledgerDivisions,
  depotsUrl,
  updateDepots,
  mapplsToken,
  mapplsDistance,
  getAllExpenses,
  deleteExpense
};

export default APIConstants;
