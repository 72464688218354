import { PERMISSIONS, ROLE } from "common/constants";
import APIConstants from "common/constants/APIConstants";
import { getFormattedValue, getUserRole } from "common/utils";
import { api } from "common/utils/APIMethods";
import { createContext, useContext, useMemo, useRef } from "react";
import { useQuery } from "react-query";
import { useAuthContext } from "./AuthContext";

const CartContext = createContext();

const getParentMetadata = (cartDetails = []) => {
  const result = {};
  cartDetails.forEach((cartDetail) => {
    result[cartDetail.id] = {
      supplierName: cartDetail.supplier.businessName,
      totalProducts: cartDetail.inventory.length,
      formattedOrderValue: getFormattedValue(cartDetail.orderValue),
      comment: cartDetail.comment,
      paymentType: cartDetail.paymentType,
      cartId: cartDetail.id,
      division: cartDetail.division,
      orderSubTypeId: cartDetail?.orderType?.transactionSubType?.id,
      minValue: cartDetail.minValue,
      orderValue: cartDetail.orderValue
    };
  });
  return result;
};

export const getCartProductMetadata = (cartDetails = []) => {
  const result = {};
  cartDetails.forEach((cartDetail) => {
    cartDetail.inventory.forEach((inventory) => {
      const {
        inventoryAvailability = {},
        orderedQuantity,
        status,
        hold
      } = inventory;
      const {
        division,
        availability,
        available,
        expiryDate,
        mrp,
        rate,
        schemes,
        id,
        productName,
        activeStatus,
        supplier
      } = inventoryAvailability;
      const { businessName } = supplier;
      const normalScheme =
        schemes?.filter(
          (scheme) => scheme?.orderType?.transactionSubType?.type === "Normal"
        )?.[0] ?? {};
      const { deal = 0, free } =
        schemes?.filter(
          (scheme) =>
            scheme?.orderType?.transactionSubType?.type ===
            cartDetail?.orderType.type
        )?.[0] ?? normalScheme;
      result[`${cartDetail.id}-${id}`] = {
        division,
        cartDivision: cartDetail.division,
        productName,
        hold,
        orderedQuantity,
        availability,
        available,
        expiryDate,
        mrp,
        rate,
        deal,
        free,
        orderSubTypeId: cartDetail?.orderType?.transactionSubType?.id,
        cartId: cartDetail.id,
        inventoryId: id,
        usingHalfSchemes: !!cartDetail?.supplier?.settings?.usingHalfSchemes,
        skipAvailabilityCheck:
          !!cartDetail?.supplier?.settings?.skipAvailabilityCheck,
        activeStatus,
        supplierRole: cartDetail.supplier?.role?.role,
        status,
        supplierName: businessName
      };
    });
  });
  return result;
};

const getParsedCartData = (cartDetails = []) => {
  const result = {};
  cartDetails.forEach((cartDetail) => {
    result[cartDetail.id] = {
      childrenInventory: cartDetail.inventory.map(
        (inventory) => inventory.inventoryAvailability.id
      ),
      placeOrderInventory: cartDetail.inventory
        .filter((inventory) => !inventory.hold)
        .map((inventory) => inventory.inventoryAvailability.id)
    };
  });
  return result;
};

export default function CartContextProvider({ children }) {
  const { parsedRoleConfig, authConfig } = useAuthContext();

  const parsedDataRef = useRef();

  const fetchCartDetails = () =>
    api(
      {
        url: APIConstants.cartDetailsURL,
        method: "GET"
      },
      authConfig
    );

  const isRoleApprover = getUserRole(authConfig) === ROLE.approver;

  const {
    data: cartDetails = [],
    refetch,
    isLoading: cartIsLoading,
    isFetching: cartIsFetching,
    isRefetching: cartIsRefetching
  } = useQuery(["cartDetails"], fetchCartDetails, {
    enabled: parsedRoleConfig
      ? parsedRoleConfig?.Cart?.indexOf(PERMISSIONS.create) > -1 &&
        !isRoleApprover
      : false
  });

  const cartData = useMemo(() => {
    parsedDataRef.current = getParsedCartData(cartDetails);
    return {
      cartDetails: cartDetails
        ?.sort((cartA, cartB) =>
          cartA?.supplier?.businessName
            ?.toUpperCase()
            ?.localeCompare(cartB?.supplier?.businessName?.toUpperCase())
        )
        ?.map((cart) => ({
          ...cart,
          inventory: cart?.inventory?.sort((a, b) =>
            a?.inventoryAvailability?.productName
              ?.toUpperCase()
              ?.localeCompare(
                b?.inventoryAvailability?.productName?.toUpperCase()
              )
          )
        })),
      parentMetadata: getParentMetadata(cartDetails),
      productMetadata: getCartProductMetadata(cartDetails),
      parsedDataRef,
      refetch,
      cartIsLoading,
      cartIsFetching,
      cartIsRefetching
    };
  }, [cartDetails, cartIsLoading, cartIsFetching, cartIsRefetching, refetch]);

  return (
    <CartContext.Provider value={cartData}>{children}</CartContext.Provider>
  );
}

export const useCartContext = () => useContext(CartContext);
