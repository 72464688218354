import ErrorBoundary from "common/components/ErrorBoundary";
import AuthContextProvider from "common/contexts/AuthContext";
import CartContextProvider from "common/contexts/CartContext";
import UserContextProvider from "common/contexts/UserContext";
import { lazy, Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Login";
import ResetPassword from "./ResetPassword";
import TermsAndConditions from "./TermsAndConditions";

const AppLayout = lazy(() => import("common/components/AppLayout"));
const Dashboard = lazy(() => import("./Dashboard"));
const PlaceOrder = lazy(() => import("./PlaceOrder"));
const Cart = lazy(() => import("./NewCart"));
const AllOrders = lazy(() => import("./AllOrders"));
const Suppliers = lazy(() => import("./Suppliers"));
const UserGroups = lazy(() => import("./UserGroups"));
const Customers = lazy(() => import("./Customer"));
const Representatives = lazy(() => import("./Representatives"));
const Attendance = lazy(() => import("./Attendance"));
const Payments = lazy(() => import("./Payments"));
const Profile = lazy(() => import("./Profile"));
const InvoiceManagement = lazy(() => import("./InvoiceManagement"));
const Inventory = lazy(() => import("./Inventory"));
const Support = lazy(() => import("./Support"));
const Notifications = lazy(() => import("./Notifications"));
const UpdateInvoice = lazy(() => import("./UpdateInvoice"));
const PaymentLanding = lazy(() => import("./PaymentLanding"));
const Configuration = lazy(() => import("./Setting"));
const LandingPage = lazy(() => import("./LandingPage"));
const Ledgers = lazy(() => import("./Ledgers"));
const UpdatesPage = lazy(() => import("./LandingPage/Updates"));
const Expenses = lazy(() => import("./Expenses"));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1
    }
  }
});

function App() {
  return (
    <AuthContextProvider>
      <QueryClientProvider client={queryClient}>
        <CartContextProvider>
          <UserContextProvider>
            <BrowserRouter>
              <ErrorBoundary>
                <Suspense fallback={<div>Loading your content...</div>}>
                  <Routes>
                    <Route path="/" element={<AppLayout />}>
                      <Route index element={<Dashboard />} />
                      <Route path="dashboard" element={<Dashboard />} />
                      <Route path="suppliers/*" element={<Suppliers />} />
                      <Route path="groups/*" element={<UserGroups />} />
                      <Route path="customers/*" element={<Customers />} />
                      <Route path="ledgers" element={<Ledgers />} />
                      <Route
                        path="representatives/*"
                        element={<Representatives />}
                      />
                      <Route path="attendance" element={<Attendance />} />
                      <Route path="placeOrder/*" element={<PlaceOrder />} />
                      <Route path="cart" element={<Cart />} />
                      <Route path="orders/*" element={<AllOrders />} />
                      <Route
                        path="invoices/*"
                        element={<InvoiceManagement />}
                      />
                      <Route path="payments/*" element={<Payments />} />
                      <Route path="profile" element={<Profile />} />
                      <Route path="inventory" element={<Inventory />} />
                      <Route path="support" element={<Support />} />
                      <Route path="notifications" element={<Notifications />} />
                      <Route path="configuration" element={<Configuration />} />
                      <Route path="expenses/*" element={<Expenses />} />
                      <Route path="*" element={<Dashboard />} />
                    </Route>
                    <Route path="/login" element={<Login />} />
                    <Route path="/reset" element={<ResetPassword />} />
                    <Route path="/terms" element={<TermsAndConditions />} />
                    <Route path="/home" element={<LandingPage />} />
                    <Route path="/updates" element={<UpdatesPage />} />
                    <Route
                      path="/payment-landing"
                      element={<PaymentLanding />}
                    />
                    <Route
                      path="/updateInvoice/:customerId/:updateId"
                      element={<UpdateInvoice />}
                    />
                  </Routes>
                </Suspense>
              </ErrorBoundary>
            </BrowserRouter>
            <ReactQueryDevtools initialIsOpen={false} />
          </UserContextProvider>
        </CartContextProvider>
      </QueryClientProvider>
    </AuthContextProvider>
  );
}

export default App;
