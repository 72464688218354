export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const phoneNumberRegex = /^([0|+[0-9]{1,5})?([6-9][0-9]{9})$/;

export const phoneNumberRegexRepresentative = /^[0-9]{10}$/;

export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

export const pinCodeRegex = /^(\d{6})$/;

export const atLeastOneValidChar = /^(?=.*[\w\d]).+/;

export const gstRegex = /^[a-zA-Z0-9]{15,15}$/;