import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const languages = ["en", "hi", "te"];
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: false,
    detection: {
      order: ["queryString", "cookie"],
      caches: ["cookie"]
    },
    whitelist: languages,

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
